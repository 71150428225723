import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavBar extends Component {
	constructor(props) {
  		super(props);
  		this.state = {
  		};
    }
      
	render() {
    	return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <Link to="/" className="navbar-brand header"> 
                        dennisneetix.com
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <Link to="/home" className="nav-link">Home</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
    	);
  	}
}

export default NavBar;