import React  from 'react';
import styled from 'styled-components';
import backgroundImage from '../images/background4.jpg';

const Div = styled.div`
    position:fixed;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    background-image: url(${backgroundImage});
    background-size:cover;
    z-index:-1;
`;

const Background = () => (
  <Div primary>
  	<div className="container" id="content">
  	</div>
  </Div>
);

export default Background;