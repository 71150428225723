import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ConnectionStatus from './connectionstatus';
import styled from 'styled-components';

const Version = styled.span`
    font-size:x-small;
`;

class Footer extends Component {
  render() {
    return (
        <footer className="footer bg-light">
            <div className="container borderTop1px">
                <div className="row text-center">
                    <div className="col text-right">
                        <Link to="/imprint" className="nav-link"><small>Impressum</small></Link>
                    </div>
                    <div className="col text-left">
                        <Link to="/privacy" className="nav-link whiteSpaceNoWrap"><small>Datenschutz</small></Link>
                    </div>
                </div>
                <div className="row">
                    <ConnectionStatus />
                </div>
                <div className="row">
                    <div className="col text-right">
                        <Version>Vers. {process.env.VERSION}</Version>
                    </div>
                </div>
            </div>
        </footer>
		);
	}
}

export default Footer;
