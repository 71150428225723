import React, { Component } from 'react';
import { connect } from "react-redux";
import styled  from 'styled-components';
import { updateIsOnlineStatus } from "../actions/index";
import reload from "../images/reload.svg";
import info from "../images/info.svg";


const InfoImage = styled.img`
  width:20px;
  height:20px;
  border-radius:7px;
  background-color:#fff;
  padding:4px;
  margin-right:5px;
`

const ReloadImage = styled.img`
  width:15px;
  height:15px;
  cursor:pointer;
`

const FadeInOutDiv = styled.div`
  border-radius: 7px;
  border: 1px solid #aeaeae;
  background-color: #aeaeae;
  color: #111;
  padding:10px;
  font-size: 0.9rem !important;

  -webkit-animation: fadeinout 2s; 
  -moz-animation: fadeinout 5s; 
  -ms-animation: fadeinout 5s; 
  -o-animation: fadeinout 5s;
  animation: fadeinout 5s;

  @keyframes fadeinout {
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeinout {
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeinout {
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadeinout {
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadeinout {
    0%   { opacity:0; }
    50%  { opacity:1; }
    100% { opacity:0; }
  }
`

const FadeInDiv = styled.div`
  border-radius: 7px;
  border: 1px solid #aeaeae;
  background-color: #aeaeae;
  color: #111;
  padding:10px;
  font-size: 0.9rem !important;

  -webkit-animation: fadein 2s; 
  -moz-animation: fadein 2s; 
  -ms-animation: fadein 2s; 
  -o-animation: fadein 2s;
  animation: fadein 2s;

  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
`

class ConnectionStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
    this.updateConnectionStatus = this.updateConnectionStatus.bind(this);
    this.updateIsLoadingState = this.updateIsLoadingState.bind(this);
  }

  componentDidMount() {
    var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if(connection) {
      connection.addEventListener('change', this.updateConnectionStatus);
      this.updateConnectionStatus();
    }
    else {
      if(navigator.onLine) {
        this.updateConnectionStatus(navigator.onLine);
        window.addEventListener("offline", this.updateConnectionStatus(false));
        window.addEventListener("online", this.updateConnectionStatus(true));
      }
    }
  }

  updateConnectionStatus(newValue = null) {
    if(newValue != null) {
      this.props.updateIsOnlineStatus(newValue);
    }
    else {
      this.setState({
        isLoading: true
      },() => {
        console.log("Connection changed. Checking connectivity...");
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = () => {
          if (xhttp.readyState === 4 && xhttp.status === 200) {
            console.log("Online");
            this.props.updateIsOnlineStatus(true);
            this.updateIsLoadingState();
          }
        };
        xhttp.onerror = () => {
          console.log("Offline");
          this.props.updateIsOnlineStatus(false);
          this.updateIsLoadingState();
        };
        xhttp.open("GET", "/connect.html", true);
        xhttp.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate"); // HTTP 1.1.
        xhttp.setRequestHeader("Pragma", "no-cache"); // HTTP 1.0.
        xhttp.setRequestHeader("Expires", "0"); // Proxies.
        xhttp.send();
      });
    }
  }

  updateIsLoadingState() {
    window.setTimeout(() => {
      this.setState({
        isLoading: !this.state.isLoading
      });
    },500);
  }

  onReCheckHandler() {
    this.updateConnectionStatus();
  }

  render() {
    return (
        <div className="col text-center" id="connectionState">
        {
          this.state.isLoading &&
          <FadeInDiv>
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </FadeInDiv>
        }
        {
          this.props.isOnline !== null && !this.state.isLoading && this.props.isOnline &&
          <FadeInDiv>
            <InfoImage src={info} alt="Info"></InfoImage>
            You are online.
          </FadeInDiv>
        }
        {
          this.props.isOnline !== null && !this.state.isLoading && !this.props.isOnline &&
          <FadeInDiv>
            <InfoImage src={info} alt="Info"></InfoImage>
            You have currently no connection. 
            <span> <ReloadImage onClick={this.onReCheckHandler.bind(this)} src={reload} alt="Re-Check"></ReloadImage></span>
          </FadeInDiv>
        }
      </div>
    );
  }
}

function mapStateToProps({ isOnline }) {
  return {
    isOnline
  };
}


function mapDispatchToProps(dispatch) {
  return {
    updateIsOnlineStatus: isOnline => dispatch(updateIsOnlineStatus(isOnline))
  };
}


const Form = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionStatus);

export default Form;