import React, { Component } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { connect } from "react-redux";
import NavBar from './components/navbar';
import Footer from './components/footer';
import Imprint from './components/imprint';
import Privacy from './components/privacy';
import Home from './components/home';
import Background from './components/background';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import styled  from 'styled-components';

const MarginTop = styled.div`
    margin-top:50px;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Background key="backgroundComponent" />
        <HashRouter>
          <div>
            <div id="main" className="container-fluid">
                <div id="content">
                  <NavBar key="navbarComponent" />
                  <MarginTop>
                    <Routes>
                      <Route path="/" element={<Home />}/>
                      <Route path="/home" element={<Home />}/>
                      <Route path="/imprint" element={<Imprint />}/>
                      <Route path="/privacy" element={<Privacy />}/>
                    </Routes>
                  </MarginTop>
                </div>
            </div>
            <Footer key="footerComponent" />
          </div>
        </HashRouter>
      </div>
    );
  }
}

export default App;