import { UPDATE_ISONLINE_STATUS } from "../constants/actiontypes";

const initialState = {
    isOnline: null
};

function rootReducer(state = initialState, action) {
    if (action.type === UPDATE_ISONLINE_STATUS) {
        return Object.assign({}, state, {
            isOnline: action.payload
        });
      
    }
    return state;
}

export default rootReducer;