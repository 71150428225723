import React from 'react';
import * as Popper from "@popperjs/core"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import './index.css';
import { Provider } from "react-redux";
import store from "./store/index";
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
/*
ReactDOM.render(
    <Provider store={store}>    
        <App />
    </Provider>, 
    document.getElementById('root')
);
*/
registerServiceWorker();
