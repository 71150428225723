import React, { Component } from 'react';
import styled  from 'styled-components';

const Background = styled.div`
    background-color:#f8f9fa !important; 
    border-radius:10px;
    padding: 10px;
    margin: 10px;
`;

class Imprint extends Component {
  render() {
    return (
        <div className="container opacity90">
            <div className="row">
                <div className="col marginBottom50px text-center">
                    <h1 className="headerFont">Impressum</h1>
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <Background>
                        Dennis Neetix Einzelunternehmung
                        <br />
                        Joseph-Veith Str. 20
                        <br />
                        41334 Nettetal
                        <br />
                        E-Mail: dennis@dennisneetix.com
                    </Background>
                </div>
            </div>
        </div>
		);
	}
}

export default Imprint;
